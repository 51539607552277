import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import * as React from "react";

import {IRootStore} from "@store/index";

import Footer from "@components/Footer";

import {recaptchaToken} from "@consts/index";
import {
    ErrorCodes,
    IThreadUpdateRequest,
    SetThreadUpdate,
    SuccessCodes,
} from "@libs/api";

import "@styles/threadview.scss";
import * as Recaptcha from "react-recaptcha";
import { IThread } from "@store/CtfStore";
import Link from "@components/Link";
import RemovableMessage from "@components/RemovableMessage";

interface IThreadViewProps {
    store?: IRootStore;
    threadId: number;
}

@inject("store")
@observer
export class ThreadView extends React.Component<IThreadViewProps, {}> {

    @observable public messageError: string = "";
    private refQuestion = React.createRef<HTMLTextAreaElement>();
    private refSubmit = React.createRef<HTMLButtonElement>();
    private recaptchaInstance: Recaptcha | null = null;

    @observable private thread?: IThread = undefined;

    constructor(props: IThreadViewProps) {
        super(props);
        makeObservable(this);
    }

    public async componentDidMount() {
        await this.props.store?.ctf.fetchMessages();
        
        this.thread = this.props.store?.ctf.threads.get(String(this.props.threadId));
        this.props.store?.ctf.markMessagesSeen(this.props.threadId);
    }

    public render() {
        if (this.props.store && !this.props.store.ctf.isLoggedIn) {
            return null;
        }

        return (
            <div className={"page threadview"}>
                <div className={"inner"}>

                    <h1 className={"mainTitle"}>Messages</h1>

                    <div className={"top"}>
                        <div className="title-pad go-back"><Link href={"/threads"} title={"Back"} child={"Back"}/></div>
                        <h2 className="title">{this.thread?.api.title}</h2>
                        <div className={"title-pad " + (this.thread?.api.closed ? 'closed' : 'close')}>
                            <button onClick={() => this.props.store?.ctf.closeThread(this.thread!.id)} disabled={this.thread?.api.closed}>{this.thread?.api.closed ? 'Closed' : 'Close'}</button>
                        </div>
                    </div>

                    <div className="messages">
                        {this.thread?.api.messages.map(msg => (
                            <div className={"message" + (msg.sender ? '' : ' from-orgs')}>
                                <h5>{msg.sender ? msg.sender : "justCTF"}</h5>
                                <div className="body">{msg.text}</div>
                            </div>
                        ))}
                    </div>

                    {!!this.thread && !this.thread.api.closed && (
                        <div className="threadFooter">
                            {this.messageError && <RemovableMessage type={"error"} time={3000}>{this.messageError}</RemovableMessage>}

                            <div className={"form"}>
                                <textarea ref={this.refQuestion} rows={5} placeholder={"Reply here... (max 0x1337)"} />
                                <button ref={this.refSubmit} onClick={this.onSubmit} type={"submit"}>»</button>
                            </div>

                            <Recaptcha
                                ref={(e) => this.recaptchaInstance = e}
                                sitekey={recaptchaToken}
                                size="invisible"
                                render={"explicit"}
                                onloadCallback={() => null}
                                verifyCallback={this.verifyCaptcha}
                                theme={"dark"}
                            />
                        </div>
                    )}

                    {!!this.thread && this.thread.api.closed && (
                        <div className="threadFooter">
                            <p>This thread is closed</p>
                        </div>
                    )}
                </div>

                <Footer sticky={true}/>
            </div>
        );
    }

    private onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.recaptchaInstance && this.recaptchaInstance.execute();
    }

    private verifyCaptcha = (captchaToken: string) => {
        const value = (this.refQuestion.current && this.refQuestion.current.value) || "";
        if (!value) {
            return;
        }
        const form: IThreadUpdateRequest = {
            thread_id: this.thread!.id,
            message: value,
            captcha: captchaToken,
        };

        this.refSubmit.current && this.refSubmit.current.setAttribute("disabled", "disabled");
        (async () => {
            this.messageError = "";
            let err = null;
            try {
                const [response, err2] = await SetThreadUpdate(form);
                if (err2) {
                    err = ErrorCodes.toHumanMessage(err2);
                } else {
                    this.props.store?.ctf.fetchMessages().then(() => {
                        this.props.store?.ctf.markMessagesSeen(this.thread!.id)
                    })
                }
            } catch (e) {
                console.error("send err", e);
                err = String(e);
            }
            if (err === null) {
                this.messageError = "";
            } else {
                this.messageError = err;
            }

            if (this.refQuestion.current) { this.refQuestion.current.value = ""; }

        })().finally(() => {
            this.refSubmit.current && this.refSubmit.current.removeAttribute("disabled");
        });
    }
}
