import React, {createContext, useContext, useMemo} from 'react';
import {useTheme} from "@nivo/core";
import {CustomCanvasLayer, PointTooltipProps, ResponsiveLineCanvas, Serie} from "@nivo/line";
import {Chip, TableTooltip, TooltipWrapper} from "@nivo/tooltip";
import {calculateChartPoints} from '../libs/task-solve-chart-helper';
import {IInfo, Task, Scoreboard} from '@store/CtfStore';
import {IMapType, Instance} from 'mobx-state-tree';

const CHART_TEAM_COUNT = 10;
const SERIE_COLORS = ["#b33dc6", "#f46a9b", "#ea5545", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#45bc96", "#27aeef"];

const CUSTOM_POINTS_LAYER: CustomCanvasLayer = ({ lineGenerator, series, ctx, lineWidth, innerWidth }) => {
  series.forEach(serie => {
    ctx.fillStyle = serie.color!;
    for (const item of serie.data) {
        if ((item.data as any).scored) {
            ctx.beginPath();
            ctx.arc(item.position.x, item.position.y, 3, 0, 2 * Math.PI);
            ctx.fill();
        }
    }
  });
}

// https://stackoverflow.com/a/29018745
function binarySearch<T>(arr: T[], el: T, compare_fn: (a: T, b: T) => number) {
    let m = 0;
    let n = arr.length - 1;
    while (m <= n) {
        let k = (n + m) >> 1;
        let cmp = compare_fn(el, arr[k]);
        if (cmp > 0) {
            m = k + 1;
        } else if(cmp < 0) {
            n = k - 1;
        } else {
            return k;
        }
    }
    return ~m;
}

const DataContext = createContext<Serie[]>([]);
const SliceTooltip = ({ point }: PointTooltipProps) => {
    const data = useContext(DataContext);
    const theme = useTheme();

    const rows = data.map((serie: Serie, idx: number) => {
        let x = binarySearch(serie.data, point.data, (x, y) => (x.x as Date).getTime() - (y.x as Date).getTime());
        if (x < -1)
            x = -x-2;

        return [
            <Chip key="chip" color={SERIE_COLORS[idx]} style={theme.tooltip.chip} />,
            serie.id,
            <span key="value" style={theme.tooltip.tableCellValue}>{x !== -1 ? serie.data[x].y!.toString() : '0'}</span>,
        ]
    });

    return (
        <TooltipWrapper anchor="right" position={[0, 0]}>
            <TableTooltip
                title={point.data.xFormatted}
                rows={rows}
            />
        </TooltipWrapper>
    )
}

const ScoreChartCanvas = ({data, minDate}: {data: Serie[], minDate: Date}) => {
  return (
      <DataContext.Provider value={data}>
          <ResponsiveLineCanvas
              data={data}
              xScale={{ type: 'time', min: minDate, precision: 'minute' }}
              xFormat="time:%A %H:%M"
              yScale={{ type: 'linear', min: 0 }}
              axisBottom={{
                  format: "%H:%M"
              }}
              enablePoints={false}
              tooltip={SliceTooltip}

              layers={[
                  'grid',
                  'markers',
                  'axes',
                  'areas',
                  'crosshair',
                  'lines',
                  CUSTOM_POINTS_LAYER,
                  'mesh',
                  'legends'
              ]}
              colors={SERIE_COLORS}
              theme={{
                  textColor: "rgba(255,255,255,0.8)",
                  grid: {line: {stroke: "#48484c"}},
                  tooltip: {container: {background: "#000"}}
              }}

              margin={{ top: 10, right: 20, bottom: 40, left: 40 }} />
      </DataContext.Provider>
  )
};

const ScoreChart = ({ctfInfo, scoreboardData, tasks}: {ctfInfo: IInfo, scoreboardData: Instance<IMapType<typeof Scoreboard>>, tasks: Instance<IMapType<typeof Task>>}) => {
    const data = useMemo(() => calculateChartPoints(ctfInfo, [...scoreboardData.values()], [...tasks.values()], CHART_TEAM_COUNT), [ctfInfo, scoreboardData, tasks]);
    return (
        <ScoreChartCanvas
            data={data}
            minDate={new Date(ctfInfo.start)} />
    )
}

export const ScoreChartLegend = ({scoreboardData}: {scoreboardData: Instance<IMapType<typeof Scoreboard>>}) => {
    const teams = [...scoreboardData.values()].slice(0, 10);
    return (
        <div className='score-chart-legend'>
            {teams.map((team, idx) => (
                <div>
                    <div className='box' style={{backgroundColor: SERIE_COLORS[idx]}} />
                    <span className='name'>{team.api.team.name}</span>
                </div>
            ))}
        </div>
    )
};

export default ScoreChart;
