import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import * as React from "react";

import {IRootStore} from "@store/index";

import Footer from "@components/Footer";

import "@styles/threads.scss";
import ThreadCreate from "@components/ThreadCreate";
import Modal from "@components/Modal";
import { IThread } from "@store/CtfStore";
import Link from "@components/Link";
import { RouterStore } from "mobx-react-router";

@inject("store", "routing")
@observer
export class ThreadsPage extends React.Component<IThreadsPageProps, {}> {
    @observable public questionShowForm: boolean = false;

    constructor(props: IThreadsPageProps) {
        super(props);
        makeObservable(this);
    }

    public async componentDidMount() {
        this.props.store.ctf.fetchMessages()
    }

    public render( ) {
        return (
            <div className={"page threads"}>
                <div className={"inner"}>
                    <h1 className={"mainTitle"}>Messages</h1>

                    <button onClick={this.onAdd} className={"addThread"}>Ask Question</button>

                    <div className={"threads"}>
                        {this.props.store && Array.from(this.props.store.ctf.threads.values()).map((row, index) => (
                            
                            <div className={"thread" + (this.getLastThreadMessage(row) > (this.props.store!.ctf.seenMessages.get(String(row.id)) || 0) ? ' highlight' : '')} onClick={this.onOpenThread} data-href={`/thread/${row.api.id}`}>
                                <h2>{row.api.title}</h2>
                                {row.api.messages.slice(-1).map(msg => (
                                    <div className="last-message">
                                        <strong>{msg.sender ? msg.sender : "justCTF"}:</strong> {msg.text}
                                    </div>
                                ))}

                                <div className="footer">{row.api.category} · {row.api.messages.length} {row.api.messages.length !== 1 ? "messages" : "message"}</div>
                            </div>
                        ))}
                    </div>

                    <Modal closable={true} active={this.questionShowForm} onCloseButtonClick={this.hideForm} onBackgroundClick={this.hideForm}>
                        <ThreadCreate />
                    </Modal>
                </div>
                <Footer sticky={true} />
            </div>
        );
    }

    private getLastThreadMessage = (thread: IThread) => {
        return Math.max(...thread!.api.messages.map(msg => msg.id))
    }

    private onAdd = ( ) => {
        this.questionShowForm = !this.questionShowForm;
    }

    private onOpenThread = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();

        const href = e.currentTarget.dataset.href;
        if ( !!href && !!this.props.routing ) {
            this.props.routing.push(href);
        }
    }

    private hideForm = ( ) => {
        this.questionShowForm = false;
    }

    public trunc( text: string, length: number ) {
        return (text.length > length) ? text.substr(0, length - 1) + "..." : text;
    }
}

interface IThreadsPageProps {
    store: IRootStore;
    routing?: RouterStore;
}
