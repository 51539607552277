import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import {RouterStore} from "mobx-react-router";
import * as React from "react";
import * as Recaptcha from "react-recaptcha";

import Footer from "@components/Footer";

import {recaptchaToken} from "@consts/index";
import {IRootStore} from "@store/index";

import {ErrorCodes, IRecoverInitRequest, SetRecoverInit, SuccessCodes} from "@libs/api";

import "@styles/recover.scss";

@inject("store", "routing")
@observer
export class RecoverInitPage extends React.Component<IRecoverInitPageProps, {}> {

    @observable public errorMessage: string = "";
    @observable public successMessage: string = "";
    private refEmailAddress = React.createRef<HTMLInputElement>();
    private refSubmit = React.createRef<HTMLButtonElement>();
    private recaptchaInstance: Recaptcha | null = null;

    constructor(props: IRecoverInitPageProps) {
        super(props);
        makeObservable(this);
    }

    public render( ) {
        return (
            <div className={"page recover"}>
                <div className={"inner"}>
                    <h1 className={"mainTitle"}>Lost Password Recovery</h1>

                    {this.errorMessage && this.errorMessage.length && <div className={"errorMessage"}>{this.errorMessage}</div>}
                    {this.successMessage && this.successMessage.length && <div className={"successMessage"}>{this.successMessage}</div>}

                    <form onSubmit={this.formSubmit}>
                        <div className={"form-group"}>
                            <label htmlFor={"emailAddress"}>e-mail</label>
                            <input type={"email"} name={"email"} placeholder={"E-MAIL"} id={"emailAddress"} ref={this.refEmailAddress} />
                        </div>

                        <button className={"submitButton"} ref={this.refSubmit} type={"submit"}>reset password</button>

                        <Recaptcha
                            ref={(e) => this.recaptchaInstance = e}
                            sitekey={recaptchaToken}
                            size="invisible"
                            render={"explicit"}
                            onloadCallback={() => null}
                            verifyCallback={this.verifyCaptcha}
                            theme={"dark"}
                        />
                    </form>

                    <Footer sticky={true} />
                </div>
            </div>
        );
    }

    private formSubmit = ( e: React.FormEvent<HTMLFormElement> ) => {
        e.preventDefault();

        this.recaptchaInstance && this.recaptchaInstance.execute();
    }

    private verifyCaptcha = (captchaToken: string) => {
        const form: IRecoverInitRequest = {
            email: (this.refEmailAddress.current && this.refEmailAddress.current.value) || "",
            captcha: captchaToken,
        };

        this.refSubmit.current && this.refSubmit.current.setAttribute("disabled", "disabled");
        (async () => {
            let err = null;
            try {
                const err2 = await SetRecoverInit(form);
                if (err2) {
                    err = ErrorCodes.toHumanMessage(err2);
                }
            } catch (e) {
                err = String(e);
            }
            if (err !== null) {
                this.errorMessage = String(err);
                this.successMessage = "";

                return;
            }

            this.errorMessage = "";
            this.successMessage = SuccessCodes.toHumanMessage(SuccessCodes.recover_sent);

            if (this.refEmailAddress.current) { this.refEmailAddress.current.value = ""; }

        })().finally(() => {
            this.refSubmit.current && this.refSubmit.current.removeAttribute("disabled");
        });

    }
}

interface IRecoverInitPageProps {
    store: IRootStore;
    routing: RouterStore;
}
