import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import * as React from "react";

import {ITask} from "@store/CtfStore";
import {IRootStore} from "@store/index";

import Challenge from "@components/Challenge";
import Footer from "@components/Footer";
import Loader from "@components/Loader";
import Modal from "@components/Modal";

import ChallengeModal from "@components/ChallengeModal";
import FlagSubmit from "@components/FlagSubmit";
import Link from "@components/Link";
import "@styles/challenges.scss";
import {RouterStore} from "mobx-react-router";

@inject("store", "routing")
@observer
export class ChallengesPage extends React.Component<IChallengesPageProps, {}> {
    @observable public selectedCategories: Set<string> = new Set();
    @observable public showUnsolved: boolean = false;

    // Modal
    @observable public fetchedTask: ITask|undefined = undefined;

    constructor(props: IChallengesPageProps) {
        super(props);
        makeObservable(this);
    }

    public async componentDidMount( ) {
        if (!this.props.store) {
            return;
        }

        this.getSettings();
        await this.props.store.ctf.fetchTasks();
        this.props.store.ctf.fetchMyTeam();
        this.props.store.ctf.fetchInfo();

        if (this.props.task_id !== undefined) {
            if (this.props.store.ctf.tasks.has(String(this.props.task_id))) {
                this.fetchedTask = this.props.store.ctf.tasks.get(String(this.props.task_id));
            }
        }
    }

    public async componentDidUpdate( ) {
        if (!this.props.store) {
            return;
        }

        if (this.props.task_id !== undefined) {
            if (this.props.store.ctf.tasks.has(String(this.props.task_id))) {
                this.fetchedTask = this.props.store.ctf.tasks.get(String(this.props.task_id));
            }
        }
    }

    public saveSettings = () => {
        const data = {
            showUnsolved: this.showUnsolved,
            selectedCategories: Array.from(this.selectedCategories),
        };
        localStorage.setItem("settingsChallengesPage", JSON.stringify(data));
    }

    public getSettings = () => {
        let data;
        try {
            data = JSON.parse(localStorage.getItem("settingsChallengesPage") || "{}");
        } catch (e) {
            return;
        }
        this.showUnsolved = data.showUnsolved || false;
        this.selectedCategories = new Set(data.selectedCategories || []);
    }

    public onUnsolved = () => {
        this.showUnsolved = !this.showUnsolved;
        this.saveSettings();
    }

    public onCategory = (categoryId: string) => () => {
        if (this.selectedCategories.has(categoryId)) {
            this.selectedCategories.delete(categoryId);
        } else {
            this.selectedCategories.clear();
            this.selectedCategories.add(categoryId);
        }
        this.saveSettings();
    }

    public onClickChallenge = (challengeId: number) => () => {
        if (!this.props.store) {
            return;
        }
        const { store: { ctf: { tasks } } } = this.props;

        if (tasks.has(String(challengeId))) {
            this.fetchedTask = tasks.get(String(challengeId));
        }
    }

    public onClickCloseChallenge = ( ) => {
        if (!!this.fetchedTask) {
            this.props.routing && this.props.routing.push("/challenges");
            this.fetchedTask = undefined;
        }
    }

    public render( ) {
        if (!this.props.store) {
            return null;
        }

        return (
            <div className={"page challenges"}>
                <div className={"inner"}>
                    <h1 className={"mainTitle"}>Challenges</h1>

                    {this.props.store.ctf.tasksState === "pending" && <Loader text={"Loading challenges"} />}
                    {this.props.store.ctf.tasksState === "error" && <Loader text={"Error during loading challenges"} />}
                    {this.props.store.ctf.tasksState === "done" && <>
                        <header>
                            <ul>
                                {Array.from(this.props.store.ctf.categories.values()).map((category) => (
                                    <li onClick={this.onCategory(category.id)}
                                        key={category.id}
                                        className={`category ${
                                            (this.selectedCategories.size === 0 ||
                                                this.selectedCategories.has(category.id)
                                            ) ? "" : "inactive"
                                        }`}
                                        style={{backgroundColor: `#${category.color}`}}>
                                        {category.name.toUpperCase()}
                                    </li>
                                ))}
                                <li className={"unsolved"}>
                                    <input type={"checkbox"} id={"iOnlyUnsolved"} checked={this.showUnsolved} onChange={this.onUnsolved} />

                                    <label htmlFor={"iOnlyUnsolved"}>
                                        <span />
                                        <p>Only unsolved</p>
                                    </label>
                                </li>
                            </ul>

                            <FlagSubmit />
                        </header>

                        <div className={"list"}>
                            {Array.from(this.props.store.ctf.filteredTasks(
                                this.selectedCategories,
                                this.showUnsolved,
                            ).values()).map((challenge) => (
                                <Link key={challenge.id} href={`/challenges/${challenge.id}`} title={challenge.api.name} child={<Challenge info={challenge} />} />
                            ))}
                        </div>

                        {!!this.fetchedTask && <Modal closable={true} active={true} onBackgroundClick={this.onClickCloseChallenge} onCloseButtonClick={this.onClickCloseChallenge}>
                            <ChallengeModal task={this.fetchedTask} />
                        </Modal>}
                    </>}

                    <Footer />
                </div>
            </div>
        );
    }
}

interface IChallengesPageProps {
    routing?: RouterStore;
    store?: IRootStore;
    task_id?: number;
}
