import {inject, observer} from "mobx-react";
import * as React from "react";

import RemovableMessage from "@components/RemovableMessage";
import {recaptchaToken} from "@consts/index";
import {
    ErrorCodes,
    IThreadCreateRequest,
    SetThreadCreate,
    SuccessCodes,
} from "@libs/api";
import {IRootStore} from "@store/index";
import {makeObservable, observable} from "mobx";
import * as Recaptcha from "react-recaptcha";

@inject("store")
@observer
class ThreadCreate extends React.Component<IThreadCreateProps, {}> {

    @observable public messageOk: string = "";
    @observable public messageError: string = "";
    private refTitle = React.createRef<HTMLInputElement>();
    private refCategory = React.createRef<HTMLSelectElement>();
    private refQuestion = React.createRef<HTMLTextAreaElement>();
    private refSubmit = React.createRef<HTMLButtonElement>();
    private recaptchaInstance: Recaptcha | null = null;

    constructor(props: IThreadCreateProps) {
        super(props);
        makeObservable(this);
    }

    public render() {
        if (this.props.store && !this.props.store.ctf.isLoggedIn) {
            return null;
        }

        if (this.props.store!.ctf.tasksState === "none") {
            this.props.store!.ctf.fetchTasks();
        }

        return (
            <div className={"questionForm"}>
                {this.messageError && <RemovableMessage type={"error"} time={3000}>{this.messageError}</RemovableMessage>}
                {this.messageOk && <RemovableMessage type={"success"} time={3000}>{this.messageOk}</RemovableMessage>}

                <h1>Ask us something</h1>

                <div className={"form"}>
                    <div className={"form-group"}>
                        <label htmlFor={"questionTitle"}>Title</label>
                        <input type={"text"} name={"questionTitle"} placeholder={"TITLE"} id={"questionTitle"} ref={this.refTitle} />
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor={"questionCategory"}>Category</label>
                        <select ref={this.refCategory} id={"questionCategory"}>
                            {["General", "Technical"].concat(Array.from(this.props.store!.ctf.tasks).map(([val, task]) => task.api.name)).map((value) => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <textarea ref={this.refQuestion} rows={10} placeholder={"Where here your question (long 0x1337)"} />
                    <button ref={this.refSubmit} onClick={this.onSubmit} type={"submit"}>Send</button>
                </div>

                <Recaptcha
                    ref={(e) => this.recaptchaInstance = e}
                    sitekey={recaptchaToken}
                    size="invisible"
                    render={"explicit"}
                    onloadCallback={() => null}
                    verifyCallback={this.verifyCaptcha}
                    theme={"dark"}
                />
            </div>
        );
    }

    private onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.recaptchaInstance && this.recaptchaInstance.execute();
    }

    private verifyCaptcha = (captchaToken: string) => {
        const value = (this.refQuestion.current && this.refQuestion.current.value) || "";
        if (!value) {
            return;
        }
        const form: IThreadCreateRequest = {
            title: (this.refTitle.current && this.refTitle.current.value) || "",
            category: (this.refCategory.current && this.refCategory.current.value) || "",
            message: value,
            captcha: captchaToken,
        };

        this.refSubmit.current && this.refSubmit.current.setAttribute("disabled", "disabled");
        (async () => {
            this.messageOk = "";
            this.messageError = "";
            let err = null;
            try {
                const [response, err2] = await SetThreadCreate(form);
                if (err2) {
                    err = ErrorCodes.toHumanMessage(err2);
                } else {
                    this.props.store?.ctf.fetchMessages().then(() => {
                        this.props.store?.ctf.markMessagesSeen(response!.thread_id)
                    })
                }
            } catch (e) {
                console.error("send err", e);
                err = String(e);
            }
            if (err === null) {
                this.messageError = "";
                this.messageOk = SuccessCodes.toHumanMessage(SuccessCodes.question_added);
            } else {
                this.messageOk = "";
                this.messageError = err;
            }

            if (this.refQuestion.current) { this.refQuestion.current.value = ""; }

        })().finally(() => {
            this.refSubmit.current && this.refSubmit.current.removeAttribute("disabled");
        });
    }
}


interface IThreadCreateProps {
    store?: IRootStore;
}


export default ThreadCreate;
