
export const nullDate = new Date("1970-01-01T00:00:00+00:00");

const _baseUrl = "/api/v1";

export const baseUrl = _baseUrl;
export const recaptchaToken = "6LeYuGolAAAAAH0KHrUuIdwpg1sb_yfHlFk93u3m";

export const competitionVersion = 2023;
export const competitonLive = false;
export const archived = true;
export const discordLink = "https://discord.gg/phyqdh6";
export const pricesList = [3200, 1500, 1000];
