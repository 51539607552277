import {inject, observer} from "mobx-react";
import * as React from "react";

import RemovableMessage from "@components/RemovableMessage";
import {ErrorCodes, SetFlag, SuccessCodes} from "@libs/api";
import {IRootStore} from "@store/index";
import {makeObservable, observable} from "mobx";

@inject("store")
@observer
class FlagSubmit extends React.Component<IFlagSubmitProps, {}> {

    @observable public messageOk: string = "";
    @observable public messageError: string = "";
    private refFlag = React.createRef<HTMLInputElement>();

    constructor(props: IFlagSubmitProps) {
        super(props);
        makeObservable(this);
    }

    public onFlagPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // on click enter
        if (e.keyCode === 13) {
            this.onFlagSend();
        }
    }

    public onFlagSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.onFlagSend();
    }

    public async onFlagSend() {
        const value = (this.refFlag.current && this.refFlag.current.value) || "";
        if (!value) {
            return;
        }

        this.messageOk = "";
        this.messageError = "";
        let err = null;
        try {
            const err2 = await SetFlag({flag: value.trim()});
            if (err2) {
                err = ErrorCodes.toHumanMessage(err2);
            }
        } catch (e) {
            console.error("send err", e);
            err = String(e);
        }
        if (err === null) {
            this.messageError = "";
            this.messageOk = SuccessCodes.toHumanMessage(SuccessCodes.solved_task);
        } else {
            this.messageOk = "";
            this.messageError = err;
        }
    }

    public render() {
        if (this.props.store && !this.props.store.ctf.isLoggedIn) {
            return null;
        }

        return (
            <div className={"submitFlag"}>
                {this.messageError && <RemovableMessage type={"error"} time={3000}>{this.messageError}</RemovableMessage>}
                {this.messageOk && <RemovableMessage type={"success"} time={3000}>{this.messageOk}</RemovableMessage>}

                <div className={"form"}>
                    <input ref={this.refFlag} onKeyDown={this.onFlagPressEnter} type={"text"} placeholder={"Flag"} />
                    <button onClick={this.onFlagSubmit} type={"submit"} />
                </div>
            </div>
        );
    }
}


interface IFlagSubmitProps {
    store?: IRootStore;
}


export default FlagSubmit;
