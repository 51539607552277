import {inject, observer} from "mobx-react";
import {RouterStore} from "mobx-react-router";
import * as React from "react";

import {IRootStore} from "@store/index";

import Footer from "@components/Footer";
import Timer from "@components/Timer";

import TrailOfBitsLogo from "../assets/images/sponsors/trail_of_bits_logo.svg";
import OtterSecLogo from "../assets/images/sponsors/ottersec_logo.svg";
import SecforceLogo from "../assets/images/sponsors/secforce_logo.svg";
import IsecLogo from "../assets/images/sponsors/isec_logo.svg";

import {competitionVersion, competitonLive, discordLink, pricesList} from "@consts/index";
import {formatToCustomJctf} from "@libs/date";
import "@styles/homepage.scss";

@inject("routing", "store")
@observer
export class HomePage extends React.Component<IHomePageProps, {}> {
    public async componentDidMount( ) {
        await this.props.store.ctf.fetchInfo( );
    }

    public render( ) {
        const { store } = this.props;
        const now = new Date();

        return (
            <div className={"page homepage"}>
                <div className={"bg"}>
                    <span/>
                    <span/>
                </div>

                <div className={"inner"}>
                    <section className={"sec1"}>
                        <div className={"logo"}>
                            just
                            <span>ctf</span>
                            {competitionVersion}
                        </div>
                        <div className={"text"}>
                            Capture The <span className={"flag"} /> Competition
                        </div>

                        <div className={"stats"}>
                            {competitonLive && !store.ctf.info.isStarted() && (
                                <>
                                    <div className={"stat"}>
                                        <div>{store.ctf.info.teams_count}</div>
                                        <p>Teams</p>
                                    </div>

                                    <div className={"stat"}>
                                        <div>{store.ctf.info.countries_count}</div>
                                        <p>Countries</p>
                                    </div>
                                </>
                            )}
                            {competitonLive && store.ctf.info.isStarted() && (
                                <>
                                    <div className={"stat"}>
                                        <div>{store.ctf.info.flags_count}</div>
                                        <p>Flags submitted</p>
                                    </div>

                                    <div className={"stat"}>
                                        <div>{store.ctf.info.teams_count}</div>
                                        <p>Teams registered</p>
                                    </div>

                                    <div className={"stat"}>
                                        <div>{store.ctf.info.tasks_unsolved_count}</div>
                                        <p>Unsolved challenges</p>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className={"timer"}>
                            {!store.ctf.info.isStarted() && (
                              <>
                                  <h4>Starts in</h4>
                                  <Timer date={store.ctf.info.start}/>
                              </>
                            )}
                            {store.ctf.info.isRunning() && (
                              <>
                                  <h4>Ends in</h4>
                                  <Timer date={store.ctf.info.end}/>
                              </>
                            )}
                            {store.ctf.info.isEnded() && (
                              <>
                                  <h4>CTF is over!</h4>
                                  <Timer date={store.ctf.info.end}/>
                              </>
                            )}
                        </div>

                        {competitonLive && !store.ctf.isLoggedIn && <a href={"/register"} className={"register"} onClick={this.redirectToRegister}>Register</a>}
                    </section>

                    <section className={"sec3"}>
                        <h1 className={"mainTitle"}>Info</h1>

                        <ul>
                            <li>
                                <h4>Start:</h4>
                                <p>{store.ctf.info.start && formatToCustomJctf(store.ctf.info.start)}</p>
                            </li>
                            <li>
                                <h4>Time:</h4>
                                <p>{store.ctf.info.diffHours()}h</p>
                            </li>
                            <li>
                                <h4>Format:</h4>
                                <p>jeopardy on-line</p>
                            </li>

                            {discordLink &&
                                <li>
                                    <h4>Join our <a href={discordLink} title={"Discord"} target={"_blank"}>Discord</a></h4>
                                </li>
                            }
                        </ul>
                    </section>

                    <section className={"sec7"}>
                        <p>
                            justCTF 2023 is an online jeopardy-style <a href={"https://ctftime.org/ctf-wtf/"} rel={"noreferrer noopener"} target={"_blank"}>[capture the flag]</a> competition organized for the 4th time.
                            <br/>
                            We will have challenges from categories such as:
                        </p>
                        <ul>
                            <li>web (web application security)</li>
                            <li>pwn (pwning, or, binary exploitation)</li>
                            <li>crypto (cryptography)</li>
                            <li>re (reverse engineering)</li>
                            <li>misc (miscellaneous)</li>
                            <li>and maybe fore (digital forensics) or stegano (steganography)</li>
                        </ul>

                        <p>See also previous editions challenges:</p>
                        <ul>
                            <li><a href={"https://github.com/justcatthefish/justctf-2019"} rel={"noreferrer noopener"} target={"_blank"}>justCTF 2019</a></li>
                            <li><a href={"https://github.com/justcatthefish/justctf-2020"} rel={"noreferrer noopener"} target={"_blank"}>justCTF [*] 2020</a></li>
                            <li><a href={"https://github.com/justcatthefish/justctf-2022"} rel={"noreferrer noopener"} target={"_blank"}>justCTF 2022</a></li>
                        </ul>

                        <p>Or writeups from them:</p>
                        <ul>
                            <li><a href={"https://blog.trailofbits.com/2021/02/02/pdf-is-broken-a-justctf-challenge/"} rel={"noreferrer noopener"} target={"_blank"}>"PDF is Broken: a justCTF Challenge"</a> - about an interesting challenge from Trail of Bits</li>
                            <li>Terjanq's <a href={"https://hackmd.io/@terjanq/justctf_writeups"} rel={"noreferrer noopener"} target={"_blank"}>justCTF 2019</a> and <a href={"https://hackmd.io/@terjanq/justCTF2020-writeups"} rel={"noreferrer noopener"} target={"_blank"}>justCTF [*] 2020</a> writeups</li>
                            <li><a href={"https://arturcygan.com/posts/2021-01-31-hacking-blind-in-2021.html"} rel={"noreferrer noopener"} target={"_blank"}>"Hacking Blind in 2021"</a> - about Artur's BROP "Pinata" challenge</li>
                            <li><a href={"https://twitter.com/disconnect3d_pl/status/1355969861231529985"} rel={"noreferrer noopener"} target={"_blank"}>Go-fs</a> - a Go bug converted into a challenge by Disconnect3d</li>
                            <li>and other writeups on CTFTime: <a href={"https://ctftime.org/event/943/tasks/"} rel={"noreferrer noopener"} target={"_blank"}>justCTF 2019</a>, <a href={"https://ctftime.org/event/1050/tasks/"} rel={"noreferrer noopener"} target={"_blank"}>justCTF [*] 2020</a>, and <a href={"https://ctftime.org/event/1631/tasks/"} rel={"noreferrer noopener"} target={"_blank"}>justCTF 2022</a></li>
                        </ul>
                    </section>

                    <section className={"sec4"}>
                        <h1 className={"mainTitle"}>Prizes</h1>

                        <ul>
                            <li>
                                <div>
                                    <h4>1st place</h4>
                                    <p>{pricesList[0]} USD</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <h4>2nd place</h4>
                                    <p>{pricesList[1]} USD</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <h4>3rd place</h4>
                                    <p>{pricesList[2]} USD</p>
                                </div>
                            </li>
                        </ul>
                    </section>

                    <section className={"sec5"}>
                        <h1 className={"mainTitle"}>Sponsors</h1>

                        <ul>
                            <li className={"tob"}><a href={"https://cutt.ly/Z5S1EnD"} rel={"noreferrer noopener"} target={"_blank"}><img src={TrailOfBitsLogo} alt={"Trail of Bits"} /></a></li>
                            <li className={"osec"}><a href={"https://cutt.ly/m5S1IEl"} rel={"noreferrer noopener"} target={"_blank"}><img src={OtterSecLogo} alt={"OtterSec"} /></a></li>
                        </ul>
                        <ul>
                            <li className={"sf"}><a href={"https://cutt.ly/06xheop"} rel={"noreferrer noopener"} target={"_blank"}><img src={SecforceLogo} alt={"Secforce"} /></a></li>
                            <li className={"isec"}><a href={"https://cutt.ly/m5S1DSq"} rel={"noreferrer noopener"} target={"_blank"}><img src={IsecLogo} alt={"ISEC"} /></a></li>
                        </ul>
                    </section>

                    <section className={"sec6"}>
                        <ul>
                            <li className={"email"}><a href={"mailto:justcatthefish@gmail.com"} title={""}>justcatthefish@gmail.com</a></li>
                            <li className={"twitter"}><a href={"https://twitter.com/justcatthefish"} title={""} target={"_blank"}>@justcatthefish</a></li>
                        </ul>
                    </section>

                    <Footer />
                </div>
            </div>
        );
    }

    public redirectToRegister = ( e: React.MouseEvent<HTMLAnchorElement> ) => {
        e.preventDefault();

        const href = e.currentTarget.attributes.getNamedItem("href");

        if ( !!href && !!this.props.routing ) {
            this.props.routing.push(href.value);
        }
    }
}

interface IHomePageProps {
    routing: RouterStore;
    store: IRootStore;
}
