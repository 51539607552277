import {Serie} from "@nivo/line";
import {getTaskPoints} from "./task-scoring-helper";
import {IInfo, IScoreboard, IScoreboardApiTeam, ITask} from "@store/CtfStore";


interface ISolveInfo {
    id: number;
    team: IScoreboardApiTeam;
    timestamp: Date;
}

function extractSolves(scoreboardData: IScoreboard[]) {
    const tasks: ISolveInfo[] = scoreboardData.flatMap(x => x.api.team.task_solved.map(task => ({
        id: task.id,
        team: x.api.team,
        timestamp: new Date(task.created_at)
    })));
    tasks.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
    return tasks;
}

export function calculateChartPoints(ctfInfo: IInfo, scoreboardData: IScoreboard[], tasks: ITask[], teamCount: number): Serie[] {
    const solves = extractSolves(scoreboardData);

    const chartPoints = new Map<IScoreboardApiTeam, {x: Date, y: number}[]>();
    const scoreboard = new Map<IScoreboardApiTeam, number>();
    const solvedByTeams = new Map<number, IScoreboardApiTeam[]>();

    for (let i = 0; i < teamCount && i < scoreboardData.length; i++) {
        scoreboard.set(scoreboardData[i].api.team, 0);
        chartPoints.set(scoreboardData[i].api.team, []);
    }

    for (const task of tasks)
        solvedByTeams.set(task.id, []);

    function addPointsForTeam(team: IScoreboardApiTeam, addPoints: number, timestamp: Date, isScored: boolean = false) {
        const currentPoints = scoreboard.get(team);
        if (currentPoints === undefined)
            return;
        scoreboard.set(team, currentPoints + addPoints);
        chartPoints.get(team)!.push({x: timestamp, y: currentPoints + addPoints, scored: isScored} as any);
    }

    for (const solve of solves) {
        const solveList = solvedByTeams.get(solve.id)!;
        const oldPoints = getTaskPoints(solveList.length);
        const newPoints = getTaskPoints(solveList.length + 1);

        for (const team of solveList) {
            addPointsForTeam(team, newPoints - oldPoints, solve.timestamp);
        }

        solveList.push(solve.team);
        addPointsForTeam(solve.team, newPoints, solve.timestamp, true);
    }

    const currentTime = new Date();
    const endDate = currentTime < ctfInfo.end ? currentTime : ctfInfo.end;

    const result: Serie[] = [];
    chartPoints.forEach((points, team) => {
        if (points.length > 0 && points[points.length - 1].x < endDate) {
            points.push({x: endDate, y: points[points.length - 1].y});
        }

        result.push({
            id: team.name,
            data: points
        })
    });
    return result;
}
